import React from 'react';
import { shape, bool } from 'prop-types';

import { useStaticProps } from '../context/static-props';
import IconFactory from '../../components-v2/icons/utils/factory-builder'; // TODO: check if we can be removed this component
import getBrandsAdsLayout from './helpers';

const BrandAdsMobile = ({ intervention }) => {
  const { lowEnd } = useStaticProps();
  const BrandAdsComponent = getBrandsAdsLayout(intervention);

  return <BrandAdsComponent {...intervention} isMobile IconFactory={IconFactory()} lowEnd={lowEnd} />;
};

BrandAdsMobile.propTypes = {
  intervention: shape({}),
  lowEnd: bool,
};

BrandAdsMobile.defaultProps = {
  intervention: null,
  lowEnd: false,
};

export default BrandAdsMobile;
